import { Box } from "@mui/material";

import { HeadlineTypography } from "../../components/Element/Typography/HeadlineTypography";
import { Task } from "./types/task.class";
import { JaDateTimeTypography } from "../../components/Element/Typography/JaDateTimeTypography";
import { useContext } from "react";
import { Role } from "../users/types/role.enum";
import { TaskStatus } from "./types/task-status.enum";
import { TaskStatusTypgraphy } from "./TaskStatusTypography";
import { TaskType } from "./types/task-type.enum";
import { TaskTitle } from "./components/title/TaskTitle";
import { CompleteQuestionTaskButton } from "./CompleteQuestionTaskButton";
import { RetryQuestionTaskButton } from "./RetryQuestionTaskButton";
import { HeaderBox } from "../../components/Layout/HeaderBox";
import { AuthContext } from "../../providers/auth.provider";
import { CreatedAt } from "../../components/shared/CreatedAt";
import { Id } from "../../components/shared/Id";
import { QuestionTaskAnswer } from "../questions/components/tasks/QuestionTaskAnswer";

type TaskDetailProps = {
  task: Task;
};
export const TaskDetail = ({ task, ...props }: TaskDetailProps) => {
  const { user } = useContext(AuthContext);

  const {
    id,
    createdAt,
    title,
    type,
    status,
    startAt,
    endAt,
    answer,
    question,
    teacher,
  } = task;

  return (
    <>
      <Box my={1}>
        <TaskTitle task={task} />
        <Id id={id} />
        <CreatedAt createdAt={createdAt} />
        <HeadlineTypography>ステータス</HeadlineTypography>
        <Box display="flex" alignItems="center" justifyContent="end">
          <TaskStatusTypgraphy status={status} />
        </Box>
        <HeadlineTypography>開始時刻</HeadlineTypography>
        <JaDateTimeTypography date={startAt} textAlign={"right"} />
        <HeadlineTypography>終了時刻</HeadlineTypography>
        <JaDateTimeTypography date={endAt} textAlign={"right"} />
        {type === TaskType.QUESTION_ANSWER &&
          (task.status === TaskStatus.REVIEWING || TaskStatus.COMPLETED) && (
            <QuestionTaskAnswer task={task} />
          )}
        {user.role === Role.ADMIN && status === TaskStatus.REVIEWING && (
          <HeaderBox>
            <RetryQuestionTaskButton task={task} />
            <CompleteQuestionTaskButton task={task} />
          </HeaderBox>
        )}
      </Box>
    </>
  );
};
