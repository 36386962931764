import { useMutation, useQueryClient } from "@tanstack/react-query";

import { axios } from "../../../tools/axios";
import { useToastStore } from "../../../stores/toast.store";
import { MutationOptions } from "../../../tools/react-query";

type SendRoomReminderVariables = {
  roomId: string;
};

export const sendRoomReminderRoom = async ({
  roomId,
}: SendRoomReminderVariables): Promise<void> => {
  return axios.post(`rooms/${roomId}/notifications`);
};

type UseSendRoomReminderOptions = {
  options?: MutationOptions<typeof sendRoomReminderRoom>;
};

export const useSendRoomReminder = ({
  options,
}: UseSendRoomReminderOptions = {}) => {
  const queryClient = useQueryClient();
  const { set } = useToastStore();

  return useMutation({
    onSuccess: async () => {
      set({
        type: "success",
        title: "リマインダーを送信しました",
      });
      queryClient.invalidateQueries({
        queryKey: ["rooms"],
      });
    },
    onError: async (error) => {
      set({
        type: "error",
        title: "リマインダーの送信に失敗しました",
      });
    },
    mutationFn: sendRoomReminderRoom,
    ...options,
  });
};
