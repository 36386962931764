import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { axios } from "../../../tools/axios";
import { useToastStore } from "../../../stores/toast.store";
import { MutationOptions } from "../../../tools/react-query";
import { UpdateRoomReservationsDto } from "../types/update-room-reservations.dto";

type UpdateRoomReservationsVariables = {
  dto: UpdateRoomReservationsDto;
};

export const updateRoomReservations = async ({
  dto,
}: UpdateRoomReservationsVariables): Promise<void> => {
  return axios.put(`rooms/reservations`, dto).then((response) => {});
};

type UseUpdateRoomReservationsOptions = {
  options?: MutationOptions<typeof updateRoomReservations>;
};

export const useUpdateRoomReservations = ({
  options,
}: UseUpdateRoomReservationsOptions = {}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { set } = useToastStore();

  return useMutation({
    onSuccess: async (student) => {
      navigate(`/rooms/reservations`, { replace: true });
      set({
        type: "success",
        title: "オンライン自習室の予約をまとめて更新しました",
      });
      await queryClient.invalidateQueries({ queryKey: ["rooms"] });
    },
    onError: async (error) => {
      console.error(error);
      set({
        type: "error",
        title: "オンライン自習室の予約を更新できませんでした",
      });
    },
    mutationFn: updateRoomReservations,
    ...options,
  });
};
