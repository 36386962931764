import React from "react";
import ReactDOM from "react-dom/client";
// class-tranformerのセットアップ
import "reflect-metadata";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getJoiScheme } from "./config.scheme";

// 環境変数の検証
if (process.env.REACT_APP_STAGE === "dev") {
  console.log(process.env);
}
const { error } = getJoiScheme().validate(process.env, {
  allowUnknown: true,
});
if (error) {
  throw new Error(`Environment variables validation error: ${error.message}`);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
