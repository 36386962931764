import { MenuItem, MenuItemProps, Typography } from "@mui/material";
import { Room } from "../../types/room.class";
import { Delete, NotificationImportant } from "@mui/icons-material";
import { RolesGuard } from "../../../../tools/RolesGuard";
import { Role } from "../../../users/types/role.enum";
import { useSendRoomReminder } from "../../api/sendRoomReminder";

type SendRoomReminderMenuItemProps = {
  room: Room;
} & MenuItemProps;

export const SendRoomReminderMenuItem = ({
  room,
  onClick,
  ...props
}: SendRoomReminderMenuItemProps) => {
  const mutation = useSendRoomReminder();

  const handleClick = () => {
    mutation.mutate({ roomId: room.id });
  };

  return (
    <RolesGuard roles={[Role.ADMIN]}>
      <MenuItem
        onClick={handleClick}
        disableRipple
        color="primary.main"
        disabled={mutation.isPending}
        {...props}
      >
        <NotificationImportant color="primary" />
        <Typography color="primary" ml={1}>
          リマインドする
        </Typography>
      </MenuItem>
    </RolesGuard>
  );
};
