import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
} from "react-hook-form";

type RoomReservationRadioGroupProps = {
  id: string;
  error: FieldError;
  control: Control<any>;
} & Partial<Omit<ControllerProps, "control">>;

export const RoomReservationRadioGroup = ({
  id,
  error,
  control,
  ...props
}: RoomReservationRadioGroupProps) => {
  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => (
        <>
          <RadioGroup
            row
            name={`radio-buttons-group-${id}`}
            {...field}
            value={field.value ? "true" : "false"}
            onChange={(e) => field.onChange(e.target.value === "true")}
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="不参加"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="参加する"
            />
          </RadioGroup>
          <FormHelperText error={!!error}>
            {typeof error?.message === "string"
              ? error.message
              : "選択してください"}
          </FormHelperText>
        </>
      )}
      {...props}
    />
  );
};
