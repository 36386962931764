import { Box, Typography } from "@mui/material";
import { RoomCalendar } from "../../features/rooms/components/calendar/RoomCalendar";
import { useRooms } from "../../features/rooms/api/getRooms";
import { LoadingBox } from "../../features/LoadingData";
import { AlertBox } from "../../features/AlertBox";
import { PageTitleTypography } from "../../components/Element/Typography/PageTitleTypography";

export const RoomListPage = () => {
  const { isLoading, isError, data: rooms } = useRooms();

  if (isLoading) {
    return <LoadingBox message="オンライン自習室を取得中..." />;
  }

  if (isError) {
    return (
      <AlertBox
        severity="error"
        title="ネットワークエラーが発生しました"
        description="情報の取得に失敗しました"
      />
    );
  }

  return (
    <>
      <PageTitleTypography>オンライン自習室</PageTitleTypography>
      <Box sx={{ textAlign: "left", width: "100%" }}>
        <Typography variant="h6">カレンダー</Typography>
        <Typography>日付をクリックすると、詳細画面へと遷移します。</Typography>
        <Box sx={{ width: "100%" }}>
          <RoomCalendar rooms={rooms} />
        </Box>
      </Box>
    </>
  );
};
