import { CalendarToday } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "../../../features/users/types/role.enum";
import { Service } from "../../../features/users/types/service.enum";
import { AuthContext } from "../../../providers/auth.provider";

export const LIstItemLesson = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useContext(AuthContext);

  if (
    user.role === Role.CUSTOMER &&
    !user.services.includes(Service.SELF_STUDY_ROOM)
  ) {
    return;
  }

  return (
    <>
      <ListItemButton
        selected={location.pathname.includes("lessons")}
        onClick={() => navigate("/lessons")}
      >
        <ListItemIcon>
          <CalendarToday />
        </ListItemIcon>
        <ListItemText primary="今日の授業" />
      </ListItemButton>
    </>
  );
};
