import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { Box } from "@mui/material";

import dayjs from "dayjs";

import { HeadlineTypography } from "../../components/Element/Typography/HeadlineTypography";
import { PageTitleTypography } from "../../components/Element/Typography/PageTitleTypography";
import { CreateRoomDto } from "../../features/rooms/types/create-room.dto";
import { useCreateRoom } from "../../features/rooms/api/createRoom";
import { TeacherSelectOptional } from "../../features/teachers/components/TeacherSelectOptional";
import { EndAtDateTimePicker } from "../../components/Element/DateTimePicker/EndAtDateTimePicker";
import { StartAtDateTimePicker } from "../../components/Element/DateTimePicker/StartAtDateTimePicker";
import { SubmitButton } from "../../features/SubmitButton";
import { Room } from "../../features/rooms/types/room.class";

export const RoomNewPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const resolver = classValidatorResolver(CreateRoomDto);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRoomDto>({
    resolver,
    defaultValues: {
      startAt: dayjs(state?.date instanceof Date ? state?.date : new Date()),
      endAt: dayjs(state?.date instanceof Date ? state?.date : new Date()),
      teacher: undefined,
    },
  });

  const mutation = useCreateRoom({
    options: {
      onSettled: (room) => {
        if (room instanceof Room) {
          navigate(`/rooms/${room.id}`);
        }
      },
    },
  });

  const onSubmit: SubmitHandler<CreateRoomDto> = async (data) => {
    if (mutation.isPending) {
      return;
    }
    mutation.mutate(data);
  };

  return (
    <>
      <PageTitleTypography>オンライン自習室を作成する</PageTitleTypography>
      <HeadlineTypography>スケジュール</HeadlineTypography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <HeadlineTypography>開始時間</HeadlineTypography>
        <StartAtDateTimePicker control={control} errors={errors} />

        <HeadlineTypography>終了時刻</HeadlineTypography>
        <EndAtDateTimePicker control={control} errors={errors} />

        <HeadlineTypography>講師を選択する</HeadlineTypography>
        <TeacherSelectOptional control={control} />

        <Box m={1} mt={3}>
          <SubmitButton disabled={mutation.isPending}>
            {mutation.isPending ? "送信中..." : "送信する"}
          </SubmitButton>
        </Box>
      </Box>
    </>
  );
};
