import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  Typography,
} from "@mui/material";
import { OpenInNew, PictureAsPdf } from "@mui/icons-material";

export const ListItemStudyGuide = (props: ListItemProps) => {
  return (
    <>
      <ListItemButton
        target="_blank"
        href="https://drive.google.com/file/d/1JtrFRkQMyr7aMZzMA2Z9X33tBbsXGwYJ/view?usp=sharing"
        rel="noreferrer"
      >
        <ListItemIcon>
          <PictureAsPdf />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{
                verticalAlign: "bottom",
                display: "inline-flex",
                alignItems: "center",
              }}
              {...props}
            >
              学習の手引き
              <OpenInNew fontSize="small" sx={{ ml: 0.25 }} />
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};
