import { Type } from "class-transformer";
import { IsDate, IsOptional } from "class-validator";
import { Teacher } from "../../teachers/types/teacher.class";
import { Dayjs } from "dayjs";
import { IsAfter } from "../../../tools/decorators/is-after.decorator";

export class CreateRoomDto {
  @Type(() => Date)
  @IsDate()
  startAt: Dayjs;

  @Type(() => Date)
  @IsDate()
  @IsAfter("startAt")
  endAt: Dayjs;

  @IsOptional()
  @Type(() => Teacher)
  teacher?: Pick<Teacher, "id">;
}
