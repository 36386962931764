import { useContext, useState } from "react";
import { BoxProps, Divider, Menu } from "@mui/material";

// ドメイン
import { Role } from "../../../users/types/role.enum";

// Authorization
import { RolesGuard } from "../../../../tools/RolesGuard";
import { AuthContext } from "../../../../providers/auth.provider";
import { HeaderBox } from "../../../../components/Layout/HeaderBox";
import { ActionButton } from "../../../../components/Element/Button/ActionButton";
import { DeleteQuestionTaskMenuItem } from "../../../tasks/DeleteQuestionTaskMenuItem";
import { ConfirmQuestionTaskMenuItem } from "./ConfirmQuestionTaskMenuItem";

import { Question } from "../../types/question.class";
import { Task } from "../../../tasks/types/task.class";

type QuestionTaskHeaderBoxProps = {
  question: Question;
  task: Task;
} & BoxProps;

export const QuestionTaskHeaderBox = ({
  question,
  task,
  ...props
}: QuestionTaskHeaderBoxProps) => {
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderBox justifyContent={"flex-end"} {...props}>
      <ActionButton
        id={`question-task-${task.id}-action-button`}
        aria-controls={
          open ? `question-task-${task.id}-action-menu` : undefined
        }
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={
          user.role === Role.TEACHER && user.teacher.id !== task.teacher.id
        }
      >
        タスクを操作する
      </ActionButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={`question-task-${task.id}-action-menu`}
        MenuListProps={{
          "aria-labelledby": `question-task-${task.id}-action-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RolesGuard roles={[Role.TEACHER]}>
          <ConfirmQuestionTaskMenuItem
            question={question}
            task={task}
            onClick={handleClose}
          />
        </RolesGuard>
        <RolesGuard roles={[Role.ADMIN]}>
          <DeleteQuestionTaskMenuItem task={task} onClick={handleClose} />
        </RolesGuard>
      </Menu>
    </HeaderBox>
  );
};
