import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

import { HeadlineTypography } from "../../components/Element/Typography/HeadlineTypography";
import { LoadingBox } from "../../features/LoadingData";
import { PageTitleTypography } from "../../components/Element/Typography/PageTitleTypography";
import { HeaderBox } from "../../components/Layout/HeaderBox";
import { CancelEditButton } from "../../components/Element/Button/CancelEditButton";
import { SaveEditButton } from "../../components/Element/Button/SaveEditButton";
import { AlertBox } from "../../features/AlertBox";
import { useRooms } from "../../features/rooms/api/getRooms";
import { AuthContext } from "../../providers/auth.provider";
import { UpdateRoomReservationsDto } from "../../features/rooms/types/update-room-reservations.dto";
import { useUpdateRoomReservations } from "../../features/rooms/api/updateRoomReservations";
import { RoomReservationRadioGroup } from "../../features/rooms/components/RoomReservationRadioGroup";
import { Box, Divider } from "@mui/material";

export const RoomListEditPage = () => {
  const { user } = useContext(AuthContext);

  const { data: rooms, isError, isPending } = useRooms();

  const resolver = classValidatorResolver(UpdateRoomReservationsDto);
  const {
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    getValues,
  } = useForm<UpdateRoomReservationsDto>({
    resolver,
    defaultValues: {},
  });

  useEffect(() => {
    rooms
      .filter(
        (room) => room.startAt.getTime() - 2 * 24 * 60 * 60 * 1000 > Date.now()
      )
      .sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
      .forEach((room) => {
        setValue(
          `rooms.${room.id}`,
          room.students.some((student) => student.id === user?.student?.id)
        );
      });
  }, [rooms, setValue, user?.student?.id]);

  const mutation = useUpdateRoomReservations();

  const onSubmit: SubmitHandler<UpdateRoomReservationsDto> = (data) => {
    if (mutation.isPending) {
      return;
    }
    mutation.mutate({ dto: data });
  };

  if (isPending) {
    return <LoadingBox message="オンライン自習室の情報を取得中です" />;
  }

  console.log(getValues("rooms"));

  if (isError) {
    return (
      <AlertBox
        severity="error"
        title="ネットワークエラー"
        description="オンライン自習室の取得に失敗しました。ネットワーク状態を確認してください。"
      />
    );
  }

  return (
    <>
      <PageTitleTypography>
        オンライン自習室をまとめて予約する
      </PageTitleTypography>

      <HeaderBox>
        <CancelEditButton onClick={() => reset()} />
        <SaveEditButton onClick={handleSubmit(onSubmit)} />
      </HeaderBox>

      {rooms
        .filter(
          (room) =>
            room.startAt.getTime() - 2 * 24 * 60 * 60 * 1000 > Date.now()
        )
        .sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
        .map((room, index) => (
          <Box key={room.id}>
            <HeadlineTypography>
              {room.startAt.toLocaleString()}
            </HeadlineTypography>
            <RoomReservationRadioGroup
              id={`rooms.${room.id}`}
              error={errors.rooms?.[room.id]}
              control={control}
              defaultValue={room.students.some(
                (student) => student.id === user?.student?.id
              )}
            />
            <Divider />
          </Box>
        ))}
    </>
  );
};
