import { MenuItem, MenuItemProps, Typography } from "@mui/material";
import { AssignmentTurnedIn } from "@mui/icons-material";
import { Task } from "../../../tasks/types/task.class";
import { useConfirmQuestionTask } from "../../api/confirmQuestionTask";
import { Question } from "../../types/question.class";
import { QuestionStatus } from "../../types/question-status.enum";

type ConfirmQuestionTaskMenuItemProps = {
  question: Question;
  task: Task;
} & MenuItemProps;

export const ConfirmQuestionTaskMenuItem = ({
  question,
  task,
  onClick,
  ...props
}: ConfirmQuestionTaskMenuItemProps) => {
  const mutation = useConfirmQuestionTask();

  const handleClick = () => {
    if (mutation.isPending) {
      return;
    }
    mutation.mutate({ questionId: question.id, taskId: task.id });
    if (onClick) {
      onClick(null);
    }
  };

  return (
    <MenuItem
      onClick={handleClick}
      disableRipple
      color="primary.main"
      disabled={
        mutation.isPending || question.status !== QuestionStatus.PENDING
      }
      {...props}
    >
      <AssignmentTurnedIn color="primary" />
      <Typography color="primary" ml={1}>
        タスクを確認する
      </Typography>
    </MenuItem>
  );
};
