import { Box, BoxProps, Typography } from "@mui/material";
import { Task } from "../../../tasks/types/task.class";
import { useEffect, useState } from "react";
import { UpdateTaskDto } from "../../../tasks/types/update-task.dto";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUpdateTask } from "../../../tasks/api/updateTask";
import { HeadlineTypography } from "../../../../components/Element/Typography/HeadlineTypography";
import { EditIconButton } from "../../../../components/Element/IconButton/EditIconButton";
import { QuestionAnswerCheckBox } from "../answer/QuestionAnswerCheckBox";
import { QuestionAnswerTextField } from "../TextField/QuestionAnswerTextField";
import { QuestionAnswerBox } from "../QuestionAnswerBox";
import { RolesGuard } from "../../../../tools/RolesGuard";
import { Role } from "../../../users/types/role.enum";

type QuestionTaskAnswerProps = {
  task: Task;
} & BoxProps<"iframe">;

export const QuestionTaskAnswer = ({
  task,
  ...props
}: QuestionTaskAnswerProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<UpdateTaskDto>({
    resolver: classValidatorResolver(UpdateTaskDto),
    defaultValues: {
      answer: `https://youtu.be/${task?.answer}`,
    },
  });

  useEffect(() => {
    setValue("answer", `https://youtu.be/${task?.answer}` || "");
  }, [setValue, task?.answer]);

  const mutation = useUpdateTask({
    options: {
      onSettled: () => setIsEditing(false),
    },
  });

  const handleConfirm: SubmitHandler<UpdateTaskDto> = async (data) => {
    if (mutation.isPending) {
      return;
    }
    mutation.mutate({ id: task.id, dto: data });
  };

  if (typeof task.answer !== "string" || task.answer === "") {
    return <Typography>まだ回答動画が作成されていません</Typography>;
  }

  return (
    <Box component="form" onSubmit={handleSubmit(handleConfirm)} {...props}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HeadlineTypography>回答動画</HeadlineTypography>
        <RolesGuard roles={[Role.ADMIN]}>
          <EditIconButton isEditing={isEditing} setIsEditing={setIsEditing} />
        </RolesGuard>
      </Box>
      <Box>
        {isEditing ? (
          <>
            <QuestionAnswerTextField
              error={!!errors.answer}
              helperText={
                !!errors.answer
                  ? errors.answer.message
                  : "作成したYouTubeのURL(e.g.https://youtu.be/***）を入力してください"
              }
              {...register("answer")}
            />
            <QuestionAnswerBox answer={getValues("answer")} />
            <QuestionAnswerCheckBox />
          </>
        ) : (
          <QuestionAnswerBox answer={task.answer} />
        )}
      </Box>
    </Box>
  );
};
