import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  Typography,
} from "@mui/material";
import { EditNote, OpenInNew } from "@mui/icons-material";
import { Service } from "../../../features/users/types/service.enum";
import { useContext } from "react";
import { AuthContext } from "../../../providers/auth.provider";
import { useLocation, useNavigate } from "react-router-dom";

export const ListItemCorrection = (props: ListItemProps) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <ListItemButton
        disabled={!user.services.includes(Service.TEST_CORRECTION)}
        selected={location.pathname.includes("exam-reviews")}
        onClick={() => navigate("/exam-reviews/")}
      >
        <ListItemIcon>
          <EditNote />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{
                verticalAlign: "bottom",
                display: "inline-flex",
                alignItems: "center",
              }}
              {...props}
            >
              模試・過去問添削
              <OpenInNew fontSize="small" sx={{ ml: 0.25 }} />
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};
