import { useMutation, useQueryClient } from "@tanstack/react-query";

import { axios } from "../../../tools/axios";
import { useToastStore } from "../../../stores/toast.store";
import { MutationOptions } from "../../../tools/react-query";
import { plainToInstance } from "class-transformer";
import { TaskStatus } from "../../tasks/types/task-status.enum";
import { Task } from "../../tasks/types/task.class";

type ConfirmQuestionTaskVariables = {
  questionId: string;
  taskId: string;
};

export const confirmQuestionTask = async ({
  taskId,
}: ConfirmQuestionTaskVariables): Promise<Task> => {
  return axios
    .post(`tasks/${taskId}/confirm`, {
      status: TaskStatus.ASSIGNED,
    })
    .then((response) => {
      return plainToInstance(Task, response.data);
    });
};

type UseConfirmQuestionOptions = {
  options?: MutationOptions<typeof confirmQuestionTask>;
};

export const useConfirmQuestionTask = ({
  options,
}: UseConfirmQuestionOptions = {}) => {
  const queryClient = useQueryClient();
  const { set } = useToastStore();

  return useMutation({
    onSuccess: async (task: Task, { questionId }) => {
      set({
        type: "success",
        title: "質問を確認しました",
      });
      await queryClient.invalidateQueries({
        queryKey: ["questions", questionId],
      });
    },
    onError: async (error) => {
      set({
        type: "error",
        title: "質問の確認に失敗しました",
      });
    },
    mutationFn: confirmQuestionTask,
    ...options,
  });
};
